<template>
  <div>
    <van-cell v-if="daan ===''">
      <template slot="icon">
        <img style="width:40px;height:40px;" src="@/assets/rabite.png" alt />
      </template>
      <template slot="title">
        <div :style="questionkkStyle">
          <p>{{data}}</p>
          <van-button round size="mini" type="default" @click="$emit('yesIntel')">是</van-button>
          <van-button round size="mini" type="default" @click="$emit('noIntel')">否</van-button>
        </div>
      </template>
    </van-cell>
    <van-cell v-else>
      <template slot="right-icon">
        <img style="width:40px;height:40px;" src="@/assets/rabite.png" alt />
      </template>
      <template slot="default">
        <div :style="daankkStyle">
          <p style="text-align:left">{{daan}}</p>
        </div>
      </template>
    </van-cell>
  </div>
</template>

<script>
export default {
    data(){
        return{
            data:'',
            daan:'',
            // flag:false,
            questionkkStyle:{
                padding:'10px 20px',
                backgroundImage:'url(' + require('../../assets/questionkk.png') + ')',
                backgroundRepeat:'no-repeat',
                backgroundSize:'100% 100%'
            },
            daankkStyle:{
                width:'20px',
                padding:'5px 15px',
                float:'right',
                backgroundImage:'url(' + require('../../assets/daankk.png') + ')',
                backgroundRepeat:'no-repeat',
                backgroundSize:'100% 100%'
            }           
        }
    },
    props: {
      questionValue: String,
      daanValue:String,
      // flagValue:Boolean,
    },
    mounted() {
      this.data = this.questionValue;
      this.daan = this.daanValue;
      // this.flag = this.flagValue;
      // console.log(this.data);
    }
}
</script>

<style scoped>
</style>