<template>
  <div>
    <CeshiDome
      v-for="item in question"
      :key="item.index"
      :questionValue="item.question"
      :daanValue="item.daan"
      @yesIntel="yesIntel"
      @noIntel="noIntel"
    ></CeshiDome>
  </div>
</template>
<script>
import CeshiDome from "./censhiDome";
export default {
  components: {
    CeshiDome
  },
  data() {
    return {
      question: [],
      list: [],
      count: 0
    };
  },
  created() {
    this.list = JSON.parse(sessionStorage.getItem("depInfo"));
    console.log(this.list[0]);
    this.question[0] = this.list[0];
    this.question[0].daan = "";
  },
  methods: {
    yesIntel() {
      //console.log("123456");
      this.question.push({
        question: this.list[this.count].question,
        depInfo: this.list[this.count].depInfo,
        daan: "是"
      });
      var dep = JSON.stringify(this.list[this.count].depInfo);
      sessionStorage.setItem("dep",dep);
      this.$router.replace({
        path: "/ITestResult"
      });
    },
    noIntel() {
      // console.log("123456");
      // this.flag = true;
      this.question.push({
        question: this.list[this.count].question,
        depInfo: this.list[this.count].depInfo,
        daan: "否"
      });
      this.count++;
      console.log(this.count <= this.list.length);
      var _this = this;
      setTimeout(function() {
        _this.question.push({
          question: _this.list[_this.count].question,
          depInfo: _this.list[_this.count].depInfo,
          daan: ""
        });
      }, 500);
      if (this.count == this.list.length) {
        this.$dialog
          .confirm({
            message:"抱歉没有您的症状,是否直接跳转到科室列表进行挂号,或者返回到上一步?",
            confirmButtonText: "跳到科室",
            cancelButtonText: "返回"
          })
          .then(() => {
            // on confirm
            this.$router.replace({
              path: "/IDepList"
            });
          })
          .catch(() => {
            // on cancel
            this.$router.replace({
              path: "/ICharaImg"
            });
          });
      }
    }
  }
};
</script>
<style scoped>
</style>